<template>
  <b-card title="Laporan Rekap Pegawai">
    <b-row>
      <b-col md="12">
        <validation-observer ref="accountRules" tag="form">
          <b-form class="mt-1" @submit.prevent>
            <b-row>
              <b-col cols="12" md="3">
                <validation-provider #default="{ errors }" name="NIP" rules="required">
                <b-form-group label="NIP" label-for="employee_nip" :state="errors.length > 0 ? false : null">
                  <v-select id="employee_nip" v-model="dataParent.employee_nip"
                    :reduce="(employee_nip) => employee_nip.nip" placeholder="Pilih Pegawai"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai" label="name" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
                <b-form-group label="Tanggal Awal" label-for="start_date">
                  <validation-provider #default="{ errors }" name="start_date" rules="required">
                    <b-form-input id="start_date" v-model="dataParent.start_date" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Tanggal Awal" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Tanggal Akhir" label-for="end_date">
                  <validation-provider #default="{ errors }" name="end_date" rules="required">
                    <b-form-input id="end_date" v-model="dataParent.end_date" type="date"
                      :state="errors.length > 0 ? false : null" placeholder="Tanggal Akhir" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="GetAllData">
                    View
                  </b-button>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-1"
                    @click="ExportExcel">
                    Export
                  </b-button>
                </b-form-group>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
      </b-col>
    </b-row>

    <table class="table" id="recaptable">
      <thead>
        <tr>
          <th>No</th>
          <th>Hari</th>
          <th>Tanggal</th>
          <th>Jam Masuk</th>
          <th>Jam Keluar</th>
          <th>Telat Masuk</th>
          <th>Pulang Awal</th>
          <th>Keterangan</th>
        </tr>
      </thead>
      <tbody>       
      </tbody>
    </table>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BAvatar,
  BButton,
  BCard,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { codeAdvance } from './code'

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import axios from 'axios';
import $ from 'jquery';

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    // BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    // Table,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  mounted() {
    $('#datatable').DataTable({
      processing: true,
      responsive: true,
    });
  },
  data: function () {
    return {
      products: [],      
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      codeAdvance,
      columns: [
        {
          label: 'NIP',
          field: 'employee_id',
        },
        {
          label: 'Nama Lengkap',
          field: 'name',
        },
        {
          label: 'L/P',
          field: 'gender',
        },
        {
          label: 'Aktif',
          field: 'is_active',
        },
        {
          label: 'Jabatan',
          field: 'employee_assignments.data.position.name',
        },
        {
          label: 'Masa Kerja',
          field: 'working_period',
        },
        {
          label: 'Usia',
          field: 'age',
        },
        {
          label: 'Action',
          field: 'action',
          width: '100px',
        },
      ],
      rows: [],
      searchTerm: '',
      modalRemove: '',
      dataParent: {
        employee_nip: '',
        start_date: '',
        end_date: '',
      },
      DataProduct: [],
      listPegawai: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getpegawai()
  },
  methods: {
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    showMsgBoxTwo(id) {
      this.modalRemove = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete Data.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeParent(id)
            this.modalRemove = value
          }
          this.modalRemove = value
        })
    },
    advanceSearch(val) {
      this.searchTerm = val
    },
    onRowClick(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.row.id}`,
          icon: 'UserIcon',
          variant: 'success',
        },
      })
    },
    toRouteAddPegawai() {
      this.$router.push({ name: 'data-pegawai-add' })
    },
    async Filter() {
      try {
        var StartDate = document.getElementById('start_date');
        var EndDate = document.getElementById('end_date');
        const PeriodeDate1 = StartDate.value.substring(0, 7);
        const PeriodeDate2 = EndDate.value.substring(0, 7);              
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },

    async ExportExcel() {
      var table = $('#recaptable').DataTable();
      table.page.len(-1).draw();
      window.open('data:application/vnd.ms-excel,' +
        encodeURIComponent($('#recaptable').parent().html()));
      setTimeout(function () {
        table.page.len(10).draw();
      }, 1000)
    },

    async GetAllData() {
      try {
        var StartDate = document.getElementById('start_date');
        var EndDate = document.getElementById('end_date');
        var EmployeeNip = document.getElementById('employee_nip');
        const PeriodeDate1 = StartDate.value;
        const PeriodeDate2 = EndDate.value;      
        //const nip = EmployeeNip.value;   
        const nip = this.dataParent.employee_nip;
        console.log(nip);
        const response = await this.$http.get(`/employeepresence/employee/recap?employee_nip=${nip}&start_date=${PeriodeDate1}&end_date=${PeriodeDate2}`)
        var DataRespone = response.data.data;
        var number = 1;
        var RecapTemp = [];
        $.each(DataRespone, function (i, item) {
          var DataDetail = DataRespone[i].details;          
          var JsonData = {
            "no": number,
            "hari": DataRespone[i].date,
            "tanggal": DataRespone[i].date,
            "time_in": DataRespone[i].time_in,
            "time_out": DataRespone[i].time_out,            
            "telatmasuk": DataRespone[i].time_in,
            "pulangawal": DataRespone[i].time_in,            
            "keterangan": DataRespone[i].presence_type.data.presence_type_desc,            
          }
          number++;
          RecapTemp.push(JsonData);
        });
        this.DataProduct = this.RecapTemp;        
        $('#recaptable').DataTable().destroy();
        var myTable = $('#recaptable').DataTable({
          "paging": true,
          "lengthChange": true,
          "searching": true,
          "ordering": true,
          "info": true,
          "autoWidth": true,
          "data": [],
          "columns": [{
            "title": "No",
            "data": "no"
          }, {
            "title": "Hari",
            "data": "hari"
          }, {
            "title": "Tanggal",
            "data": "tanggal"
          }, {
            "title": "Jam Masuk",
            "data": "time_in"
          }, {
            "title": "Jam Keluar",
            "data": "time_out"
          }, {
            "title": "Telat Masuk",
            "data": "time_in"
          }, {
            "title": "Pulang Awal",
            "data": "time_in"
          }, {
            "title": "Keterangan",
            "data": "keterangan"
          }],          
        });
        myTable.clear();
        $.each(RecapTemp, function (index, value) {
          console.log(value);
          myTable.row.add(value);
        });
        myTable.draw();
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Data Not Success')
        console.log(err)
      }
    },
    riseUpModalRemove(val) {
      this.showMsgBoxTwo(val)
    },    
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
